#CheapestDestinations{
    margin: 70px 0;
}
#CheapestDestinations .container {
    max-width: 1430px;
}
#CheapestDestinations .destionation-Slider-wrapper {
    position: relative;
}
#CheapestDestinations .destionation-Slider-wrapper .Cat-icon {
    position: absolute;
    z-index: 3;
    top: -100px;
    right: 40px;
}
#darkMode #CheapestDestinations .title h2 {
    color: var(--white);    
}
#darkMode #CheapestDestinations .title p{
    color: var(--gray);
}
@media only screen and (max-width: 1199px){
    #CheapestDestinations .container{
        max-width: 100%;
        padding: 0;
    }
    #CheapestDestinations .row{
        margin: 0;
    }
    #CheapestDestinations .col-12{
        padding: 0;
    }
    #CheapestDestinations .title.text-center {
        text-align: left !important;
        margin-bottom: 30px;
        padding: 0 15px;
        max-width: 380px;
    }
}
@media only screen and (max-width: 991px){
    #CheapestDestinations .destionation-Slider-wrapper .Cat-icon {
        position: absolute;
        z-index: 3;
        top: -140px;
        right: 180px;
    }
}
@media only screen and (max-width: 767px){
    #CheapestDestinations .destionation-Slider-wrapper .Cat-icon {
        position: absolute;
        z-index: 3;
        top: -75px;
        right: 90px;
        max-width: 110px;
    }
    #CheapestDestinations .title.text-center {
        max-width: 240px;
    }
}
@media only screen and (max-width: 576px){
    #CheapestDestinations .destionation-Slider-wrapper .Cat-icon {
        position: absolute;
        z-index: 3;
        top: -75px;
        right: 35px;
        max-width: 110px;
    }
    
}

