.flight-information {
  padding: 0;
  padding-bottom: 50px;
  background: transparent linear-gradient(180deg, #3b6de1 0%, transparent 100%)
    0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding-top: 30px;
  width: 96%;
  margin-left: auto;
  height: calc(100% - 73px);
  @media (max-width:991px) {
    height: auto;
  }
}

.title-cards-outer {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  padding: 0 30px;
  gap: 30px;
  img {
    width: 40px;
    margin-top: 8px;
    @media (max-width:1199px) {
      margin-top: 4px;
    }
    @media (max-width:767px) {
      width: 33px;
      margin-top: 2px;
    }
  }
}

.flight-information .title .text {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.title-cards-outer h5 {
  font-size: 36px;
  text-align: center;
  color: var(--white);
  @media (max-width:1199px) {
    font-size: 32px;
  }
  @media (max-width:767px) {
    font-size: 24px;
  }
}

#darkMode {
  .flight-information .title .text h5 {
    color: var(--white);
  }
  .flight-information .title .text h5 span {
    color: var(--white);
  }
}

.flight-information .title .text h5 span {
  color: var(--white);
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 10px;
}

.flight-information .title .text p {
  font-size: 22px;
  font-weight: 600;
  color: var(--green);
  margin: 0 0 0 10px;
  padding-left: 10px;
  border-left: 2px solid var(--green);
}

.flight-information .title-dropdown {
  flex: 0 0 100px;
}

.flight-information .title-dropdown .dropdown .dropdown-toggle {
  background-color: var(--transparent);
  outline: none !important;
  border: none;
  margin: 0;
  box-shadow: none !important;
  padding: 7px 15px;
  padding-right: 28px;
  border-radius: 25px;
  display: inline-block;
  position: relative;
  color: var(--gray);
  border: 1px solid var(--gray);
  width: 100%;
  text-align: left;
  font-family: var(--noto);
  font-size: 12px;
}

.flight-information .title-dropdown .dropdown .dropdown-toggle:hover,
.flight-information .title-dropdown .dropdown .dropdown-toggle:active,
.flight-information .title-dropdown .dropdown .dropdown-toggle:focus {
  color: #fff;
  background-color: var(--transparent) !important;
  border: 1px solid var(--gray);
  box-shadow: none;
  color: var(--gray);
}

.flight-information .title-dropdown .dropdown-toggle::after {
  position: absolute;
  content: "\f107";
  border: none;
  font-family: var(--fontawasome);
  font-weight: 600;
  right: 15px;
  color: var(--gray);
}

.title-dropdown .dropdown-menu {
  min-width: 160px;
  padding: 10px;
  text-align: left;
  border: none;
  border-radius: 6px;
}

.flight-card-holder {
  width: 320px;
}

.title-dropdown .dropdown-item {
  display: block;
  width: 100%;
  padding: 7px 10px;
  clear: both;
  color: var(--default-color);
  text-align: inherit;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  font-family: var(--noto);
  font-size: 12px;
  border-radius: 25px;
}

.title-dropdown .dropdown-item:focus,
.title-dropdown .dropdown-item:hover {
  color: var(--white);
  background-color: var(--pink);
}

#darkMode .title-dropdown .dropdown-menu {
  background: #091e30;
}

#darkMode .title-dropdown .dropdown-item {
  color: #c4cdd5;
}

#darkMode .footer-bottom .container {
  border-top: 1px solid #c4cdd4;
}

// Fight Slider
.flight-slider {
  padding: 0 30px;
  position: relative;
  // &::before {
  //   content: " ";
  //   width: 16%;
  //   height: -webkit-fill-available;
  //   display: block;
  //   position: absolute;
  //   z-index: 1;
  //   background-image: linear-gradient(90deg, #091e30, #091e3054, transparent);
  // }
  // &::after {
  //   content: " ";
  //   width: 16%;
  //   height: 100%;
  //   display: block;
  //   position: absolute;
  //   right: 30px;
  //   bottom: 0;
  //   background-image: linear-gradient(90deg, transparent, #091e3054, #091e30);
  // }
}

.flight-slider .slick-slide {
  padding: 0 5px;
}
.flight-slider .slick-slider {
  display: flex;
  justify-content: center;
}

.flight-slider .slick-list {
  padding: 0 !important;
}

.flight-slider .each-slide {
  // box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
  width: fit-content;
  margin: auto;
}

.flight-card-wrapper {
  position: relative;
  padding: 14px 0;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
  --mask: radial-gradient(18px at 90px, #0000 97%, #000) 0 -99px;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

#darkMode .flight-card-wrapper {
  box-shadow: none;
}

.white-bg-image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.flight-content-holder {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 170px;
  padding: 0 25px;
  background-color: #fff;
}

.flight-content-holder .icon {
  flex: 0 0 65px;
  width: 65px;
  height: 100%;
  position: relative;
  padding-right: 5px;

  &::after {
    content: url(../../../assets/images/divider.svg);
    position: absolute;
    top: 18px;
    right: -4px;
    // width: 2px;
    // height: 90%;
    // background-image: url();
  }
}

.flight-content-holder .depart,
.flight-content-holder .arrive {
  display: flex;
  height: 50%;
  flex-direction: column;
  justify-content: center;
  padding: 5px 0;
}

.flight-content-holder .text-holder {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  margin-left: 24px;
  justify-content: space-between;
  height: 100%;
}

.flight-content-holder .text-holder .left {
  text-align: right;
  height: 100%;
}

.flight-content-holder .text-holder .center {
  text-align: center;
  height: 100%;
}

.flight-content-holder .text-holder p {
  margin: 0;
  color: #808a9c;
  font-weight: 600;

  &.date {
    font-weight: 400;
    font-size: 10px;
  }
}

.flight-content-holder .text-holder p span {
  display: block;
  font-size: 8px;
  color: var(--default-color);
  font-family: var(--noto);
}

.flight-content-holder .text-holder h5 {
  font-size: 20px;
  margin-top: 1px;
}

.flight-content-holder .action-holder {
  flex: 0 0 100px;
  max-width: 100px;
  text-align: right;
  height: 100%;
  padding-left: 8px;
}

.flight-content-holder .text-holder .addon {
  margin: 0;
  font-size: 16px;
  color: var(--blue);
  font-weight: 600;
}

.flight-content-holder .action-holder .action-btn {
  font-size: 13px;
  color: #fff;
  background-color: var(--green);
  padding: 10px 15px;
  border-radius: 6px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-family: var(--noto);
}

.flight-slider .slick-arrow {
  border: 1px solid var(--default-color);
  background-color: transparent;
  color: var(--default-color);
  bottom: 50%;
  cursor: pointer;
}

.flight-slider .slick-arrow:hover {
  border: 1px solid var(--green);
}

#darkMode .flight-slider .slick-arrow {
  color: var(--white);
  background-color: rgba(0, 0, 0, 0.2);
}

#darkMode .flight-slider .slick-arrow:hover {
  border: 1px solid var(--green);
}

.flight-slider .slick-slide img {
  display: block;
  margin: 0 auto;
}

.flight-slider .action-holder .addon {
  color: var(--blue);
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  font-family: var(--noto);
  margin: 10px 0;
}

.flight-slider .action-holder .action-btn {
  color: var(--white);
  background-color: var(--green);
  font-size: 14px;
  font-weight: 700;
  font-family: var(--noto);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 10px 20px #05ce7859;
}

.flight-slider .slick-slide {
  padding: 34px 8px;
  opacity: 0.8;
  transition: transform 500ms ease-in-out;
}

.flight-slider {
  .slick-slide.slick-active.slick-current {
    transform: translateY(-32px);
    margin-top: 0 !important;
    opacity: 1;
    + .slick-slide {
      -webkit-mask: linear-gradient(90deg, #000, #0000, #0000, #0000, #0000);
      mask: linear-gradient(90deg, #000, #0000, #0000, #0000, #0000);
    }
  }
  .slick-slide {
    &:has(+ .slick-slide.slick-active.slick-current) {
      -webkit-mask: linear-gradient(90deg, #0000, #0000, #0000, #0000, #000);
      mask: linear-gradient(90deg, #0000, #0000, #0000, #0000, #000);
    }
  }
}

.flight-slider .flight-card-wrapper {
  max-width: 100%;
  width: 700px;
}

.flight-slider .custom-pagination {
  position: absolute;
  width: 60px;
  bottom: -80px;
  color: var(--default-color);
  // transform: translateX(-6%);
}

.flight-slider .slick-arrow.arrow-right {
  transform: none;
  right: 0;
}

.flight-slider .slick-arrow.arrow-left {
  transform: none;
  left: 0;
}

.box-flight-wrapper {
  border-radius: 9px;
  overflow: hidden;

  .flight-box-detail {
    display: flex;
    width: 100%;
    padding: 0 12px;
    min-width: 310px;
    background-color: var(--white);
    height: 174px;
    align-items: center;
    &.return-flight {
      border-top: 1px solid #00000026;
    }

    h5 {
      font-size: var(--fs-14);
      font-weight: 600;
    }

    p {
      margin: 0;
      font-size: 10px;
    }

    .flight-details {
      width: 100%;

      .departure-details {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        gap: 5px;

        img {
          height: fit-content;
          max-width: 80px;
        }
      }

      .flight-mid-details {
        display: flex;
        width: 100%;
        padding: 8px 10px;
        background-color: #bdc6d743;
        margin: 10px 0;

        .icon-text {
          font-size: 10px;

          svg {
            margin-right: 5px;
            color: var(--blue);
          }
        }

        > div {
          width: 50%;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }

      .arrival-details {
        display: flex;
        justify-content: space-between;
        gap: 5px;
      }
    }

    .icons-left {
      padding-right: 8px;

      .line {
        width: 1px;
        height: 75px;
        border-right: 1px dashed #333;
        margin: auto;
      }

      img {
        padding: 10px 0;
      }
    }

    .text-right {
      text-align: right;
      white-space: nowrap;
    }
  }

  .pricing-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem 1rem;
    background-color: var(--white);
    // position: relative;

    // &::before {
    //   content: "";
    //   position: absolute;
    //   width: 90%;
    //   height: 1px;
    //   background-image: linear-gradient(to right, transparent 50%, #e6e8ec 0%);
    //   background-size: 12px 100%;
    //   bottom: auto;
    //   top: 0;
    //   left: auto;
    //   left: 50%;
    //   transform: translateX(-50%);
    // }

    .price {
      margin: 4px 0 0;
      font-weight: 600;
      color: var(--blue);
      font-size: 26px;
      letter-spacing: -1px;

      p {
        margin: 0;
      }

      span {
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0;
        font-family: var(--noto);
      }
    }

    a.action-btn {
      font-size: 13px;
      color: #fff;
      background-color: var(--green);
      padding: 12px 15px;
      border-radius: 6px;
      max-width: 100px;
      width: 100%;
      text-align: center;
      font-weight: 700;
      font-family: var(--noto);
      box-shadow: 0px 10px 20px #05ce7859;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .flight-card-wrapper::before {
    background-size: cover;
  }
  .flight-card-wrapper::after {
    background-size: cover;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .flight-slider {
    &::before {
      width: 10%;
    }
    &::after {
      content: " ";
      width: 10%;
    }
  }
  .flight-information .title .text h5 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .flight-content-holder {
    padding: 0 5px;
  }

  .flight-slider .flight-card-wrapper {
    max-width: 100%;
    width: 450px;
  }

  .flight-content-holder .text-holder {
    margin-left: 6px;
  }
  .flight-content-holder .action-holder {
    flex: 0 0 80px;
    max-width: 80px;
  }
  .flight-content-holder .text-holder p.date {
    font-size: 8px;
  }
}

@media only screen and (max-width: 991px) {
  .flightslide-outer {
    width: 100% !important;
  }

  .flight-information .title .text p {
    font-size: 16px;
  }

  .flight-slider .slick-slide.slick-active.slick-center.slick-current {
    margin-top: 0;
  }

  .flight-card-wrapper {
    padding: 14px 0;
    // margin-bottom: 15px;
  }

  .flight-content-holder .icon {
    flex: 0 0 55px;
    width: 55px;
  }

  .flight-content-holder .text-holder .center,
  .flight-content-holder .text-holder .left,
  .flight-content-holder .text-holder .right,
  .flight-content-holder .text-holder .action-holder {
    padding: 0 5px;
  }

  .flight-content-holder .text-holder p {
    font-size: 15px;
  }

  .flight-content-holder .text-holder p span {
    font-size: 7px;
  }

  .flight-content-holder .text-holder h5 {
    font-size: 12px;
    margin-top: 5px;
  }

  .flight-content-holder .action-holder .action-btn {
    font-size: 12px;
    padding: 5px 10px;
    width: auto;
  }

  .flight-content-holder .text-holder .addon {
    font-size: 12px;
  }
}

// .each-slide.selected {
//   border: 1px solid var(--green);
//   border-radius: 10px;
// }

@media only screen and (max-width: 767px) {
  .flight-slider .flight-card-wrapper {
    max-width: 100%;
    width: 100%;
  }

  .flight-content-holder .text-holder p.date {
    font-size: 7px;
  }

  .flightslide-outer {
    width: 100% !important;
  }

  .showmore-flights {
    padding: 0 !important;
    margin-left: 0 !important;
    flex-direction: column;

    img {
      height: 25px !important;
      widows: 33;
    }

    p {
      text-align: center;
      margin-left: 0 !important;
    }
  }

  .flight-information .title .text p {
    font-size: 12px;
  }

  .flight-information .title-dropdown .dropdown .dropdown-toggle {
    padding: 5px 10px;
    font-size: 10px;
    padding-right: 26px;
  }
  .flight-content-holder {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .flight-card-wrapper {
    --mask: radial-gradient(16px at 59px, #0000 97%, #000) 0 -116px;
    -webkit-mask: var(--mask);
    mask: var(--mask);
  }
  .flight-information .title .text h5 {
    font-size: 22px;
  }

  .showmore-rooms {
    margin-left: 0px !important;
  }

  .search-page .quick-filter-wrapper .serach-action {
    margin-bottom: -48px;
  }

  .flight-content-holder .text-holder p.date {
    font-size: 7px;
  }

  .flightslide-outer {
    width: 100% !important;
  }

  .showmore-flights {
    padding: 0 !important;
    margin-left: 0 !important;
    flex-direction: column;

    img {
      height: 25px !important;
      widows: 33;
    }

    p {
      text-align: center;
      margin-left: 0 !important;
      font-size: 12px;
    }
  }

  .flight-information .title .text p {
    font-size: 12px;
  }

  .flight-content-holder .icon::after {
    right: 0px;
  }

  .flight-information .title-dropdown .dropdown .dropdown-toggle {
    padding: 5px 10px;
    font-size: 10px;
    padding-right: 26px;
  }
  .flight-content-holder {
    padding: 0 4px;
  }
  .flight-content-holder {
    height: 205px;
    padding-bottom: 35px;
  }
  .text-holder {
    flex-wrap: wrap;

    > div {
      display: inline-block !important;
      padding: 0 1px !important;
    }

    > div:last-child {
      width: 100% !important;
      max-width: 100%;
      flex: auto;
      display: flex !important;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 6px;
      height: auto;

      .addon {
        margin: auto 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) and (min-width: 577px) {
  .flight-card-wrapper {
    --mask: radial-gradient(18px at 67px, #0000 97%, #000) 0 -99px;
    -webkit-mask: var(--mask);
    mask: var(--mask);
  }
}

@media only screen and (max-width: 767px) {
  .flight-slider {
    padding: 0;
    .slick-slide {
      padding: 34px 6px;
    }
  }
  #flights-section {
    padding: 0;
  }
}

@media only screen and (max-width: 576px) {
  // .flight-slider .slick-slide {
  //   padding: 0;
  // }
  .flight-content-holder .text-holder {
    margin-left: 10px;
  }
  .flight-card-wrapper {
    background-color: #fff;
    --mask: radial-gradient(14px at 67px, #0000 97%, #000) 0 -116px;
    -webkit-mask: var(--mask);
    mask: var(--mask);
  }
  .flight-content-holder {
    height: 205px;
    padding-bottom: 35px;
  }
  .text-holder {
    flex-wrap: wrap;

    > div {
      display: inline-block !important;
    }

    > div:last-child {
      width: 100% !important;
      max-width: 100%;
      flex: auto;
      display: flex !important;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 6px;
      height: auto;

      .addon {
        margin: auto 0;
      }
    }

    > div:not(:last-child) {
      flex: 1 !important;
    }
  }
  .flight-information .title .text h5 {
    font-size: 24px;
    span {
      font-size: 12px;
    }
  }
}
