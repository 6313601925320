.modal-dialog {
  max-width: 580px;
}
.modal-content {
  border: none;
  border-radius: 12px;
  outline: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 25px 25px 45px;
}
.modal-header {
  justify-content: flex-start;
  padding: 0;
  border-bottom: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.modal-header .btn-close {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  padding: 0;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e");
  border: 0;
  border-radius: 0;
  opacity: 4;
  outline: none !important;
  box-shadow: none !important;
  position: absolute;
  z-index: 9;
}
.modal-body {
  padding: 25px 0 0;
  text-align: center;
}
.auth-form .login-option {
  margin: 30px 0;
}
.auth-form .login-option .gray-btn {
  background-color: #bdc6d7;
  padding: 12px 15px;
  color: #fff;
  border-radius: 50px;
  max-width: 130px;
  width: 100%;
  text-align: center;
  font-family: var(--noto);
  margin: 0 10px;
}
.auth-form .login-option .gray-btn:first-child {
  margin-left: 0;
}
.auth-form .login-option .gray-btn:last-child {
  margin-right: 0;
}
.auth-form .form-group {
  position: relative;
  max-width: 350px;
  margin: 30px auto;
}
.auth-form.login .form-group {
  margin: 50px auto 20px;
}
.auth-form .form-group input {
  border: none;
  border-bottom: 1px solid var(--lightgray);
  width: 100%;
  padding: 10px 0;
  outline: none !important;
  box-shadow: none !important;
}
.auth-form .form-group .eyeicon {
  position: absolute;
  right: 10px;
  background-color: transparent;
  border: none;
  color: var(--gray);
  top: 11px;
  font-size: 20px;
}
.auth-form .form-group.password-field {
  position: relative;
}
.auth-form .signup-form {
  position: relative;
  max-width: 350px;
  margin: auto;
}
// .auth-form .signup-form .form-group {
//     position: static;
// }
// .auth-form .signup-form .form-group .submit-btn {
//     right: 90px;
// }
.auth-form .form-group .icon {
  position: absolute;
  right: 0;
  top: 8px;
}
.auth-form .form-group .submit-btn svg {
  transition: all 0.3s ease 0s;
}
.auth-form .form-group .submit-btn:hover svg {
  transform: translateX(5px);
}
.auth-form .login-btn {
  max-width: 350px;
  margin: auto;
}
.auth-form .login-btn button {
  background-color: var(--green);
  border: none;
  color: var(--white);
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-family: var(--noto);
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.auth-form .static-link {
  margin-top: 30px;
}
.auth-form .static-link:hover {
  color: var(--lightgray);
}
.success-content {
  max-width: 285px;
  margin: auto;
}

.auth-form .social-icons {
  width: 40px;
  border: 1px solid var(--lightgray);
  padding: 5px;
  border-radius: 6px;
  margin: 10px 5px;
}

.mt-30 {
  margin-top: 30px ;
}

.departure-modal {
  position: relative;
  .btn-apply {
    background: var(--green);
    cursor: pointer;
    color: #fff;
    padding: 10px 16px;
    display: block;
    border-radius: 13px;
    text-align: center;
    border: none;
    width: auto;
    position: absolute;
    right: 0;
    top: -10px;
  }
}
.hotelcard-modal {
  .modal-dialog {
    max-width: 880px;
  }
  .modal-header {
    justify-content: flex-end;
  }
  .modal-body {
    text-align: left;
  }
  .modal-content {
    padding-bottom: 10px;
  }
  .for-small {
    display: none;
  }
}

#darkMode .flights-modal-dialogue {
  .modal-content {
    background-color: var(--default-color);
    .modal-body h5.card-title {
      color: var(--white);
    }
  }
  .btn-close {
    filter: invert(1);
  }
}
.flights-modal-dialogue {
  max-width: 800px;
  width: 90%;
  margin: auto;
  .modal-content {
    background-color: var(--white);
    padding: 40px;
    @media (max-width: 767px) {
      padding: 20px;
    }
    .modal-title.h4 {
      color: var(--green);
      font-size: 14px;
      font-weight: 400;
    }
    .btn-close {
      right: 40px;
      width: 16px;
      height: 16px;
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: center;
    }
    .modal-body {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      @media (max-width: 767px) {
        flex-direction: column;
      }
      h5.card-title {
        color: var(--default-color);
        text-align: left;
        font-size: 14px;
        margin-bottom: 10px;
      }
      .flight-card-box {
        // background-color: var(--white);
        // padding: 12px;
        .seprator-mask {
          display: none;
        }
        .flight-summary {
          // margin-bottom: 40px;
          // position: relative;
          h5 {
            color: var(--green);
          }
        }
        .flight-box {
          background-color: var(--white);
          padding: 12px;
        }
        .flight-details-outer {
          background-color: var(--white);
          padding: 1px 12px;
        }
        h5 {
          font-size: var(--fs-14);
          font-weight: 600;
        }
        p {
          margin: 0;
          font-size: 10px;
        }
        .middle-text {
          font-size: 10px;
          color: #091e30;
          img {
            margin-right: 6px;
          }
          .card-logo-img {
            max-height: 30px;
          }
        }
        .flight-details-number {
          display: flex;
          justify-content: space-evenly;
          font-size: 10px;
          border: 1px solid #e8ebf1;
          padding: 5px 10px;
          border-radius: 30px;
          margin: 10px;
          position: relative;
          background-color: var(--white);
          div {
            max-width: 45%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &::before {
            content: "";
            width: 10px;
            position: absolute;
            height: 1px;
            background-color: #e8ebf1;
            left: -10px;
            top: 12px;
          }
          &::after {
            content: "";
            width: 10px;
            position: absolute;
            height: 1px;
            background-color: #e8ebf1;
            right: -10px;
            top: 12px;
            z-index: 1;
          }
        }
        .travel-logo {
          max-width: 90px;
          max-height: 30px;
          object-fit: contain;
        }
      }
      .departure,
      .return {
        width: 100%;
        .flight-box-main {
          filter: drop-shadow(2px 4px 6px #33333339);
          .flight-card-box {
            overflow: hidden;
            &:first-child {
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              .seprator-mask {
                display: block;
              }
            }
            &:last-child {
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
              .flight-details-outer {
                padding-bottom: 15px;
              }
            }
          }
        }
      }
    }
  }
}

.seprator-mask {
  width: 100%;
  height: 30px;
  background-color: var(--white);
  padding: 12px;
  --mask: radial-gradient(15px at 100px, #0000 95%, #000) -100px 0;
  mask: var(--mask);
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, transparent 50%, #e6e8ec 0%);
    background-size: 12px 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.btn-surprise {
  background: var(--green);
  cursor: pointer;
  color: #fff;
  padding: 10px 16px;
  display: block;
  border-radius: 8px;
  text-align: center;
  border: none;
  width: 100%;
  margin-top: 10px;
}

#darkMode .room-cards-modal {
  .modal-content {
    background-color: var(--default-color);
    color: var(--white);
  }
  .more-info {
    span {
      color: var(--lightgray);
    }
  }
  ul.list {
    li {
      color: var(--gray);
    }
  }
  .btn-close {
    filter: invert(1);
  }
}
.room-cards-modal {
  max-width: 700px;
  @media (max-width: 576px) {
    width: 90%;
    margin: auto;
  }
  .modal-body {
    padding-left: 30px;
    padding-right: 30px;
    @media (max-width: 576px) {
      padding-left: 0;
      padding-right: 0;
    }
    .view-gallery-action a {
      color: var(--default-color);
    }
  }
  .btn-close {
    font-size: 16px;
    width: 16px;
    height: 16px;
    margin-bottom: -50px;
    margin-left: -8px;
    @media (max-width: 576px) {
      margin-bottom: -20px;
      font-size: 12px;
      width: 14px;
      height: 14px;
    }
  }
  .star svg {
    margin-right: 5px;
    font-size: 13px;
  }

  .star svg.filled {
    color: #ffc500;
  }
  .star svg.blank {
    color: #777e90;
  }
  .modal-content {
    background-color: var(--white);
    color: var(--default-color);
    text-align: left;
    padding-bottom: 35px;
  }
  .more-info {
    .star > span {
      padding-right: 8px;
    }
    span {
      color: var(--gray);
      font-size: 12px;
      font-family: var(--poppings);
    }
  }
  #detailsImage {
    margin: 0;
    .container {
      padding: 0;
      @media (max-width: 576px) {
        .image-holder.small {
          height: 340px;
          flex: 0 0 260px;
          max-width: 260px;
        }
      }
    }
  }
  h2 {
    font-size: 28px;
    text-align: left;
    margin-bottom: 5px;
    margin-top: 24px;
    @media (max-width: 576px) {
      font-size: 22px;
    }
  }
  h6 {
    text-align: left;
    color: var(--green);
    font-size: 22px;
    margin-top: 30px;
  }
  .star {
    text-align: left;
  }
  ul.list {
    text-align: left;
    margin-top: 10px;
    li {
      font-size: 16px;
      color: var(--gray);
      padding-bottom: 5px;
      font-family: var(--noto);
      position: relative;
      &:nth-child(even)::before {
        content: "";
        position: absolute;
        background-color: var(--gray);
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        @media (max-width: 576px) {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) and (min-width: 576px) {
  .hotelcard-modal .modal-content {
    width: 90%;
    margin: auto;
  }
}

@media only screen and (max-width: 767px) {
  .departure-modal .btn-apply {
    position: relative;
    top: 0;
  }
  .login-option {
    margin: 20px 0;
  }
  .auth-form .form-group {
    margin: 20px auto;
  }
  .hotelcard-modal {
    .for-dekstop {
      display: none;
    }
    .for-small {
      display: block;
    }
    .modal-content {
      padding: 25px 10px 10px 10px;
      .btn-close {
        margin-right: 4px;
      }
    }
  }
}

.search-form input {
  text-align: start;
  // Place your css here
}

// Departure Modal

.search-form .form-group input {
  border: 1px solid var(--lightgray);
  width: 100%;
  padding: 15px;
  outline: none !important;
  box-shadow: none !important;
  margin-top: 30px;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.suggestion-list {
  margin-top: 30px;
}
.suggestion-list li {
  display: block;
  margin-bottom: 5px;
  background-color: var(--veryLight);
  padding: 8px 15px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
}
