#footer {
  background-color: var(--veryLight);
  padding: 100px 0 0;
  margin-top: 150px;
}

#footer .cat-icon {
  display: inline-block;
  position: static;
  margin-top: -270px;
  width: 160px;
  border-radius: 100%;
  background: #fff;
  padding: 8px;
}

.footer-items {
  margin-bottom: 50px;
}

.footer-items h6 {
  margin-bottom: 15px;
}

.link-items li {
  width: 100%;
  margin-bottom: 6px;
}

.footer-items a {
  display: block;
  margin-bottom: 10px;
}

.footer-items a:hover {
  text-decoration: underline;
  transform: translateX(5px);
  color: var(--gray);
}

.footer-items a img {
  max-width: 110px;
}

.newsletter {
  text-align: right;
}

.newsletter input {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none !important;
  border-bottom: 1px solid var(--gray);
  width: 100%;
  margin-bottom: 15px;
  padding: 5px;
}

#darkMode .newsletter input {
  color: var(--white);
}

.newsletter button {
  background: var(--green);
  border: none;
  // box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 15px 30px #05CE7874;
  outline: none !important;
  padding: 10px 15px;
  color: var(--white);
  max-width: fit-content;
  width: 100%;
  text-align: center;
  border-radius: 4px;
}

.footer-middle {
  padding-bottom: 30px;
}

.powered-by-text {
  color: #596c7f;
  font-size: 12px;
}

.powered-by-text img {
  margin: 0 5px;
}

.footer-action ul {
  text-align: right;
}

.footer-action ul li svg {
  margin-right: 6px;
}

.footer-action .dropdown-toggle {
  background-color: var(--transparent);
  outline: none !important;
  margin: 0;
  box-shadow: none !important;
  display: inline-block;
  position: relative;
  // border: 1px solid #c4cdd5;
  border: 1px solid #c4cdd580;
  padding: 10px 15px;
  min-width: 135px;
  text-align: center;
  margin-right: 10px;
  border-radius: 25px;
  font-size: 16px;
  color: #596c7f;
  height: 50px;

  &::after {
    display: none;
  }

  img {
    margin-right: 5px;
  }
}

.footer-action .dropdown-menu {
  min-width: 260px;
  padding: 10px;
  text-align: left;
  border: none;
  border-radius: 8px;
}

#darkMode .footer-action .dropdown-menu {
  color: var(--white);
  background-color: var(--default-color);
}

#darkMode .footer-action .dropdown-menu .dropdown-item {
  color: var(--lightgray);

  &.active {
    color: var(--white);
  }
}

.footer-action h6 {
  padding: 5px 15px;
  padding-top: 10px;
  font-size: 16px;
  font-weight: 600;
}

.footer-action .dropdown-item {
  display: block;
  width: 100%;
  padding: 10px 15px;
  clear: both;
  font-weight: 400;
  color: var(--default-color);
  text-align: inherit;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  font-family: var(--noto);
  font-weight: 400;
  font-size: var(--default-font-size);
  border-radius: 25px;
  &.active {
    font-weight: 600;
  }
}

.footer-action .dropdown-item.active,
.footer-action .dropdown-item:active {
  color: #fff;
  background-color: var(--green);
}

.footer-action ul li:first-child .action-btn {
  min-width: 120px;
}

.footer-action ul li .action-btn {
  // border: 1px solid #c4cdd5;
  border: 1px solid #c4cdd580;
  padding: 10px 15px;
  min-width: 135px;
  text-align: center;
  margin-right: 10px;
  border-radius: 25px;
  font-size: 16px;
  color: #596c7f;
  height: 50px;
}

.footer-action ul li:last-child .action-btn {
  margin: 0;
}

.footer-bottom .container {
  padding: 30px 15px;
  border-top: 1px solid var(--gray);
}

.footer-bottom .static-pages-link li a {
  color: #596c7f;
  font-size: 12px;
  border-left: 1px solid;
  padding: 0 15px;
}

#darkMode .footer-bottom a:hover {
  color: var(--white);
}

.static-pages-link li:first-child a {
  border: none;
  padding-left: 0;
}

.social-links {
  text-align: right;
}

.footer-bottom .social-links li a {
  color: #596c7f;
  padding-left: 25px;
  font-size: 16px;
}

#darkMode .footer-bottom .social-links li a {
  color: var(--white);
}

#darkMode #footer {
  background-color: #112638;
}

#footer .for-mobile,
#footer .for-tabs {
  display: none;
}

.footer-dropdown .dropdown .dropdown-toggle {
  background-color: var(--transparent);
  outline: none !important;
  border: none;
  margin: 0;
  font-size: var(--default-font-size);
  box-shadow: none !important;
  padding: 15px;
  border-radius: 6px;
  display: inline-block;
  position: relative;
  color: var(--gray);
  border: 1px solid var(--gray);
  width: 100%;
  text-align: left;
}

.footer-dropdown .dropdown .dropdown-toggle:hover,
.footer-dropdown .dropdown .dropdown-toggle:active,
.footer-dropdown .dropdown .dropdown-toggle:focus {
  color: #fff;
  background-color: var(--transparent) !important;
  border: 1px solid var(--gray);
  box-shadow: none;
  color: var(--gray);
}

.footer-dropdown .dropdown-toggle::after {
  position: absolute;
  content: "\f107";
  border: none;
  font-family: var(--fontawasome);
  font-weight: 600;
  right: 15px;
  color: var(--gray);
}

.footer-dropdown .dropdown-menu {
  min-width: 100%;
  padding: 10px;
  text-align: left;
  border: none;
  border-radius: 8px;
}

.footer-dropdown .dropdown-item {
  display: block;
  width: 100%;
  padding: 10px 15px;
  clear: both;
  font-weight: 400;
  color: var(--default-color);
  text-align: inherit;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  font-family: var(--noto);
  font-weight: var(--fw-bold);
  font-size: var(--default-font-size);
  border-radius: 25px;
  svg {
    margin-right: 3px;
  }
}

.footer-dropdown .dropdown-item:focus,
.footer-dropdown .dropdown-item:hover {
  color: var(--white);
  background-color: var(--pink);
}

#darkMode .footer-dropdown .dropdown-menu {
  background: #091e30;
}

#darkMode .footer-dropdown .dropdown-item {
  color: #c4cdd5;
}

#darkMode .footer-bottom .container {
  // border-top: 1px solid #c4cdd4;
  border-top: 1px solid #c4cdd580 !important;
}

.footer-dropdown .dropdown-accordion {
  .accordion-button {
    color: var(--default-color);
    padding: 10px 15px;
    font-family: var(--noto);
    font-weight: var(--fw-bold);
    font-size: var(--default-font-size);
    img {
      margin-right: 6px;
    }
  }
  .accordion-body {
    padding: 10px 15px;
    background: #00000017;
    border-radius: 10px;
    h6 {
      font-size: var(--default-font-size);
      margin-bottom: 10px;
    }
  }
}
#darkMode .footer-dropdown .dropdown-accordion {
  .accordion-button {
    color: #c4cdd5;
  }
  .accordion-body {
    background: #ffffff17;
    h6 {
      color: #c4cdd5;
    }
  }
}

@media only screen and (max-width: 991px) {
  #footer .for-tabs {
    display: block;
  }

  #footer .hide-on-tab {
    display: none;
  }

  .footer-items {
    margin-bottom: 30px;
  }

  #footer .col-lg-3.col-md-12 {
    max-width: 320px;
    margin-left: auto;
  }

  #footer .cat-icon {
    max-width: 120px;
    margin-top: -190px;
  }
  #footer {
    margin-top: 100px;
    padding: 70px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  #footer .for-desktop {
    display: none;
  }

  #footer .for-mobile {
    display: block;
  }

  #footer .col-lg-3.col-md-12 {
    max-width: 100%;
    margin-left: auto;
  }

  #footer .col-lg-6.col-md-7.col-12 {
    order: 2;
  }

  .powered-by-text {
    margin: 30px 0 0;
  }

  .static-pages-link li a {
    border-left: none;
  }

  .powered-by-text img {
    margin: 0 5px;
    max-width: 90px;
  }
}
