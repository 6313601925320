.static-page {
  .body-wrapper {
    margin-top: 3%;
    background: #ffffff80;
    border-radius: 16px;
    padding: 38px;
    backdrop-filter: blur(8px);
    color: var(--default-color);
    h2 {
      // color: var(--default-color);
      margin-bottom: 20px;
    }
    p {
      //   color: var(--default-color);
      font-size: 16px;
      font-family: var(--noto);
      margin: 0 0 25px;
    }
    .left-action-back-btn {
      a {
        color: var(--white);
        margin: 0 0 25px;
      }
    }
    a {
      font-size: 16px;
      font-family: var(--noto);
      color: var(--blue);
    }
    ul {
      padding-left: 2rem;
      margin: 0 0 25px;
      li {
        list-style-type: disc;
        display: list-item;
        // color: var(--default-color);
        font-size: 16px;
        font-family: var(--noto);
        margin: 0 0 10px;
      }
    }
    h5 {
      margin-bottom: 12px;
    }
    h1 {
      font-size: var(--fs-24);
    }
  }
}
#darkMode {
  .static-page {
    .body-wrapper {
      background: #0000004e;
      backdrop-filter: blur(8px);
      color: #ffffffcd;
      h2 {
        color: var(--white);
      }
      h5 {
        color: var(--white);
      }
    }
  }
}
