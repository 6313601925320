/*---- Root Variables ----*/
:root {
    /*---- Font family Stack ----*/
    --noto: 'Noto Sans', sans-serif;
    --poppings: 'Poppins', sans-serif;
    /* --fontawasome: 'Font Awesome 5 Pro'; */
    --fontawasome: 'Font Awesome 6 Free';

    /*---- Color Set ----*/
    --default-color: #091E30;
    --black: #000C24;
    --orange: #FF8700;
    --white: #fff;
    --transparent: transparent;
    --pink: #777E90;
    --green: #05CE78;
    --gray: #777E90;
    --darkgray: #596C7F;
    --lightgray: #BDC6D7;
    --veryLight: #EDEEF1;
    --blue: #1F6DE7;

    /*----Typography ---*/
    --default-font-size: 14px;
    --fs-70: 70px;
    --fs-60: 60px;
    --fs-48: 48px;
    --fs-42: 42px;
    --fs-38: 38px;
    --fs-36: 36px;
    --fs-32: 32px;
    --fs-24: 24px;
    --fs-20: 20px;
    --fs-18: 18px;
    --fs-17: 17px;
    --fs-16: 16px;
    --fs-14: 14px;
    --fs-12: 12px;
    --fs-10: 10px;
    --fw-bold: 700;
    --fw-medium: 500;
    --fw-noraml: 400;
    --smooth: all 0.3s ease 0s;

    /*---- Common Variable ----*/
    --p-0: 0;
    --m-0: 0;
    --m-15: 15px;
    --p-15: 15px;
}


/*---- Global styling ----*/
*,
body {
    box-sizing: border-box;
}

body {
    padding: var(--p-0);
    margin: var(--m-0);
    font-size: var(--default-font-size);
    color: var(--default-color);
    line-height: 1.4;
    font-weight: 400;
    font-family: var(--poppings);
}

html,
#root,
body {
    height: 100%;
}

.main-wrapper {
    min-height: 100%;
}

.body-wrapper {
    min-height: 100vh;
}

html {
    scroll-behavior: smooth;
}


p {
    margin: 10px 0;
}

a {
    text-decoration: none !important;
    transition: var(--smooth);
    display: inline-block;
    color: var(--default-color);
    font-family: var(--noto);
    cursor: pointer;
}

a:hover,
a:focus {
    text-decoration: none;
    color: var(--white);
    outline: none !important;
}

ul,
ol {
    padding: var(--p-0);
    margin: var(--m-0);
}

li {
    list-style: none;
    display: inline-block;
}

img {
    width: auto;
    max-width: 100%;
    height: auto;
}

@media (min-width: 1400px) {
    .container {
        max-width: 1230px;
    }
}

.covered-image {
    transition: var(--smooth);
    height: 100%;
    object-fit: cover;
    width: 100%;
    max-width: 100%;
}

/*---- Genric classess ----*/


/*---- Typography ----*/
.font-noto {
    font-family: var(--noto);
}

.font-poppings {
    font-family: var(--poppings);
}

.heading,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: var(--m-0);
}

h1,
.heading-1 {
    font-size: var(--fs-48);
}

h2,
.heading-2 {
    font-size: var(--fs-42);
}

h3,
.heading-3 {
    font-size: var(--fs-36);
}

h4,
.heading-4 {
    font-size: var(--fs-32);
}

h5,
.heading-5 {
    font-size: var(--fs-24);
}

h6,
.heading-6 {
    font-size: var(--fs-20);
}

.fs-70 {
    font-size: var(--fs-70);
}

.fs-60 {
    font-size: var(--fs-60);
}

.fs-38 {
    font-size: var(--fs-38);
}

.fs-36 {
    font-size: var(--fs-36);
}

.fs-24 {
    font-size: var(--fs-24);
}

.fs-20 {
    font-size: var(--fs-20);
}

.fs-18 {
    font-size: var(--fs-18);
}

.fs-17 {
    font-size: var(--fs-17);
}

.fs-16 {
    font-size: var(--fs-16);
}

.fs-14 {
    font-size: var(--fs-14);
}

.fs-12 {
    font-size: var(--fs-12);
}

.fs-10 {
    font-size: var(--fs-10);
}

.bold {
    font-weight: var(--fw-bold);
}

.medium {
    font-weight: var(--fw-medium);
}

.normal {
    font-weight: var(--fw-noraml);
}

/*--- Coloring ----*/
.dark-text {
    color: var(--default-color);
}

.dark-bg {
    background-color: var(--default-color);
}

.black-text {
    color: var(--black);
}

.black-bg {
    background-color: var(--black);
}

.white-text {
    color: var(--white);
}

.white-bg {
    background-color: var(--white);
}

.orange-text {
    color: var(--orange);
}

.orange-bg {
    background-color: var(--orange);
}

.pink-text {
    color: var(--pink);
}

.pink-bg {
    background-color: var(--pink);
}

.green-text {
    color: var(--green);
}

.green-bg {
    background-color: var(--green);
}

.gray-text {
    color: var(--gray);
}

.gray-bg {
    background-color: var(--gray);
}

.darkgray-text {
    color: var(--darkgray);
}

.darkgray-bg {
    background-color: var(--darkgray);
}

.lightgray-color {
    background-color: var(--lightgray);
}

.lightgray-bg {
    background-color: var(--lightgray);
}

.blue-color {
    background-color: var(--blue);
}

.blue-bg {
    background-color: var(--blue);
}

.veryLight-color {
    background-color: var(--veryLight);
}

.veryLight-bg {
    background-color: var(--veryLight);
}

.no-margin {
    margin: var(--m-0);
}

.no-padding {
    margin: var(--p-0);
}

.ellipis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.mobile-only {
    display: none;
}

.dropdown-menu {
    z-index: 9;
}

/*---- Card Fav and Badge Icon ----*/

.fav-icon {
    background-color: #fff;
    display: flex;
    justify-content: center;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    position: absolute;
    right: 15px;
    top: 15px;
    border-radius: 100%;
    align-items: center;
}

.fav-icon:hover {
    transform: rotate(360deg);
}

.fav-icon svg {
    font-size: 18px;
    color: var(--lightgray);
}

.fav-icon svg.favorite {
    color: var(--green);
}

.card-badge {
    position: absolute;
    left: -50px;
    padding: 7px;
    text-align: center;
    transform: rotate(-45deg);
    top: 20px;
    max-width: 175px;
    width: 100%;
    background: transparent linear-gradient(180deg, #FFBC00 0%, #FF8700 100%) 0% 0% no-repeat;
}

.card-badge img {
    display: inline-block;
}

.card-badge span {
    display: block;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    margin-top: 3px;
}

.card-middle-area {
    position: relative;
    /* background-color: #fff; */
    /* --mask: radial-gradient(20px at 100px, #0000 97%, #000) -100px 26px;
    -webkit-mask: var(--mask);
    mask: var(--mask); */
}

.card-white-image {
    position: absolute;
    width: 100%;
    z-index: 1;
    height: 100%;
}

.rc-slider-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #e9e9e9;
    border-radius: 6px;
}

.scroll-lock {
    overflow: hidden;
}

.left-action-back-btn {
    a {
        border: 1px solid var(--gray);
        padding: 7px 25px;
        border-radius: 25px;
        color: var(--white);
    }
    &.quick-filter-back {
        a {
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
} 

#darkMode {
    .left-action-back-btn {
        &.quick-filter-back {
            a {
                background-color: rgba(0, 0, 0, 0.6);
                color: var(--white);
            }
        }
    } 
}

@media only screen and (max-width: 991px) {
    :root {
        --fs-70: 50px;
        --fs-60: 50px;
        --fs-48: 36px;
        --fs-38: 32px;
        --fs-36: 32px;
        --fs-24: 18px;
    }

    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: block;
    }
}

@media only screen and (max-width: 767px) {
    .floating-image {
        display: none;
    }

    :root {
        --fs-70: 36px;
        --fs-60: 26px;
        --fs-38: 24px;
        --fs-36: 24px;
        --fs-24: 16px;
        --fs-48: 28px;
    }
}
