.package-country {
  .slick-slide {
    padding: 40px 8px;
  }
  .globe-card-header {
    height: 150px;
  }
  .globe-card-header {
    .text {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 12px 15px;
      h6 {
        z-index: 1;
        position: relative;
      }
    }
    &::after {
      content: "";
      width: 100%;
      height: 50%;
      display: block;
      position: absolute;
      bottom: 0;
      background-image: linear-gradient(360deg, #000000b0, transparent);
    }
  }
}

.packages-wrapper, .CheapestDestinations-wrapper, .search-result-wrapper {
  .slick-arrow {
    border: 1px solid transparent;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 40px;
    bottom: 42%;
    z-index: 2;
    font-size: 22px;
    cursor: pointer;
    box-shadow: 0 0 10px #ffffff;
    &.arrow-right {
      transform: none;
      right: 30px !important;
      left: auto !important;
      @media (max-width:767px) {
        right: 10px !important;
      }
    }
    &.arrow-left {
      transform: none;
      right: auto !important;
      left: 30px !important;
      @media (max-width:767px) {
        left: 10px !important;
      }
    }
    &:hover {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.9);
      border: 1px solid var(--lightgray);
    }
  }
}
