.mobile-price-section {
  // background-color: var(--blue);
  padding: 30px 0 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  display: none;
  background-image: url(../../assets/images/curved-mask.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.double_section {
  padding-top: 20px;
  margin-bottom: 50px;
}

.desktop-price-section {
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #1f6de7;
  .container {
    // max-width: 1060px;
    .bluebox-bg {
      position: relative;
      // border-radius: 10px;
      padding: 8px 30px;
      .tags-total {
        // border-right: 1px solid #00000036;
        // border-left: 1px solid #00000036;
        padding: 0 16px;
        width: 40%;
      }
      .steps-box {
        width: 80%;
        height: 10px;
        filter: none;
        margin-bottom: 30px;
        margin-top: 38px;
        .circle {
          background-color: #1f6de7;
          width: 50px;
          height: 50px;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .active.circle {
          background-color: var(--orange);
          img {
            filter: brightness(0) invert(1);
          }
        }
        .completed.circle {
          border-color: var(--orange);
        }
        .circle svg {
          fill: var(--orange);
        }
        .step-label {
          margin-top: 6px;
          //max-width: min-content;
          text-align: center;
        }
        .step {
          width: auto;
        }
        .RSPBstep {
          &:nth-last-child(2) {
            .step {
              width: max-content;
              .step-label {
                //max-width: 76px;
              }
            }
          }
        }
      }
      .price {
        text-align: start;
        font-size: 28px;
        width: 26%;
        padding-right: 16px;
        .flipper_box {
          .currency-icon {
            color: #1f6de7;
            background-color: #ffffff;
            font-size: 35px;
            width: 42px;
            height: 60px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            border-radius: 3px;
          }
        }

        .floater-total-taxes {
          margin-left: 16px;
          margin-top: 8px;
        }
      }
      .total {
        font-size: 20px;
        font-family: var(--noto);
        font-style: italic;
        margin: 0 0 5px 0;
        color: var(--white);
        em {
          color: #00000055;
          font-weight: 700;
          text-decoration: line-through;
        }
      }
      .average-price {
        color: #ffffff;
        font-size: 14px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        margin-top: 8px;
        .currency {
          font-size: 24px;
          margin-left: 10px;
        }
        .amount {
          font-size: 35px;
          line-height: 34px;
          margin-left: 4px;
        }
      }
      .text-white {
        margin-left: 26px;
        font-size: 12px;
      }
      .badge {
        background: #091e3040;
        padding: 10px;
        font-size: 16px;
        margin-right: 8px;
        font-family: var(--noto);
        font-weight: 500;
      }
      .price-info-holder {
        border-top: 0;
        padding-top: 0;

        .action {
          flex: 0 0 30%;
          max-width: 30%;
          text-align: right;
          overflow: visible;
          .genius-icon {
            margin-bottom: -14px;
            margin-top: -90px;
            z-index: 9999;
            position: relative;
            width: 86px;
          }
          a {
            font-size: 22px;
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            color: var(--white);
            font-weight: 600;
            text-align: center;
            width: auto;
            img {
              width: 55.5px;
              top: 0;
            }
          }
        }
        .flipUnitContainer {
          span {
            color: #ff8700;
            background-color: #ffffff;
            font-size: 44px;
            width: 42px;
            height: 60px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
          }
        }
      }
    }
  }
}
.mobiles-price-section {
  display: none;
  .container {
    max-width: 1060px;
    .bluebox-bg {
      .tags-total {
        display: flex;
        justify-content: space-between;
      }
      .price {
        display: flex;
        align-items: center;
        gap: 5px;
        @media (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
          .floater-total-taxes {
            margin-left: 0;
            .local-taxes.desktop {
              display: none;
            }
          }
        }
      }
      .total {
        font-size: 20px;
        font-family: var(--noto);
        font-style: italic;
        margin: 10px 0;
        color: var(--white);
        &.mobile {
          display: none;
        }
        em {
          color: #00000055;
          font-weight: 700;
          text-decoration: line-through;
        }
      }
      .average-price {
        color: var(--white);
        font-size: 14px;
        margin-top: 0;
        display: flex;
        align-items: flex-end;
        margin-bottom: 5px;
        .currency {
          font-size: 24px;
          margin-left: 10px;
        }
        .amount {
          font-size: 35px;
          line-height: 34px;
          margin-left: 4px;
        }
      }
      .text-white {
        margin-left: 26px;
        font-size: 12px;
      }
      .badge {
        background: #1126384a;
        padding: 10px;
        font-size: 14px;
        margin-right: 5px;
        font-family: var(--noto);
        font-weight: 500;
      }
      .price-info-holder {
        border-top: 0;
        padding-top: 0;

        .action {
          flex: 0 0 300px;
          max-width: 300px;
          height: 68px;
          a {
            font-size: 22px;
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            // img {
            //   width: 55.5px;
            //   top: 0;
            // }
          }
        }
        .flipUnitContainer {
          span {
            color: #ff8700;
            background-color: #ffffff;
            font-size: 35px;
            width: 38px;
            height: 56px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            //margin-left: 6px;
          }
        }
      }
    }
  }
  .custom-shape-divider-bottom-1695738255 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 166px;
    overflow: hidden;
    line-height: 0;
    background-image: url(../../assets/images/backrgound_pricefloater_blur.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: bottom;
  }

  .custom-shape-divider-bottom-1695738255 svg {
    position: relative;
    display: block;
    width: calc(300% + 1.3px);
    height: 200px;
    display: none;
  }

  .custom-shape-divider-bottom-1695738255 .shape-fill {
    fill: #a3a3a384;
  }
}

.main-wrapper.flight-page {
  background-image: url(../../assets/images/main-background-light.png),
    url(../../assets/images/blog-background.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover, 0;
}

.information-toggler a {
  color: #fff;
  font-size: 20px;
}

.price-info-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--lightgray);
  padding-top: 10px;
}

.price-info-holder .price {
  font-size: 16px;
  color: var(--white);
  text-align: right;
}

.price-info-holder p {
  text-align: left;
  font-size: 12px;
  margin: 0;
  font-family: var(--noto);
  font-weight: 400;
  margin-bottom: 3px;
  margin-top: 8px;
}

// .price-info-holder span {
//   font-size: 26px;
//   font-weight: 600;
//   margin-left: 3px;
//   display: inline-block;
//   color: var(--whie);
//   width: 28px;
//   height: 40px;
//   line-height: 40px;
//   text-align: center;
//   background-color: rgba(255, 255, 255, 0.2);
//   vertical-align: middle;
//   border-radius: 5px;
// }

.price-info-holder .action {
  flex: 0 0 190px;
  max-width: 190px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.price-info-holder .action div {
  display: flex;
  justify-content: flex-end;
  p {
    margin-left: 25px;
  }
}

.price-info-holder .action a {
  background-color: #06b972;
  color: #fff;
  padding: 14px 45px 14px 45px;
  width: 100%;
  font-family: var(--poppins);
  font-weight: 700;
  font-size: 12px;
}

.price-info-holder .action a img {
  position: absolute;
  background: #399f56;
  padding: 10px 15px;
  bottom: 0;
  right: 0;
}

.forSmaller {
  display: none;
}
.forDesktop {
  display: block;
}

@media only screen and (max-width: 1199px) {
  .desktop-price-section .container .bluebox-bg
  {
    .price-info-holder .action a {
      font-size: 22px;
      padding: 18px 30px;
    }
    .badge {
      padding: 10px;
      font-size: 14px;
      margin-right: 6px;
    }
  } 
}

@media only screen and (max-width: 991px) {
  .flight-information {
    width: 100%;
    margin-top: 30px;
  }
  .room-information {
    width: 100%;
  }
  .desktop-price-section {
    .container {
      max-width: 100%;
      .bluebox-bg {
        .badge {
          padding: 8px;
          font-size: 14px;
          margin-bottom: 5px;
        }
        .average-price {
          flex-direction: column;
          align-items: flex-end;
        }
        .price .flipper_box .currency-icon {
          font-size: 28px;
          padding: 6px;
          height: 50px;
        }
        .price-info-holder {
          .flipUnitContainer {
            width: 32px;
            height: 50px;
            span {
              font-size: 35px;
            }
          } 
          .action {
            flex: 0 0 30%;
            max-width: 30%;
            text-align: end;
            padding-left: 0;
            a {
              font-size: 15px;
              color: var(--white);
              font-weight: 500;
              width: auto;
              padding: 16px 25px;
              img {
                width: 48px;
              }
            }
          }
        } 
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .detail-card-wrapper {
    position: sticky;
    top: 8px;
    display: none;
  }
  .flight-page footer {
    padding-bottom: 220px !important;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-price-section {
    display: block;
  }
  .desktop-price-section {
    display: none;
  }
  .mobiles-price-section {
    display: block;
    padding: 40px 0 15px 12px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    background-image: url(../../assets/images/curved-mask.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    .custom-shape-divider-bottom-1695738255 {
      display: none;
    }
    .flipper_box {
      margin-left: -16px;
      gap: 4px !important;
      font-size: 18px;
      align-items: flex-start !important;
    }
    .bluebox-bg {
      background-color: transparent !important;
      padding: 0 !important;
      .total {
        font-size: 16px !important;
        &.desktop {
          display: none;
        }
        &.mobile {
          display: block !important;
          margin: 2px 0 0 0;
        }
      }
      .badge {
        padding: 6px 4px !important;
        font-size: 9px !important;
        margin-right: 3px !important;
        border-radius: 3px;
      }
      .average-price {
        font-size: 10px !important;
        margin-top: 0 !important;
        flex-direction: column;
        align-items: flex-end;
        .amount {
          font-size: 30px !important;
          line-height: 30px !important;
          margin-left: 3px !important;
        }
      }
      .local-taxes {
        font-size: 10px !important;
        color: var(--white);
      }
    }
    .price-info-holder {
      .text-white {
        font-size: 10px !important;
      }
      .price {
        font-size: 24px !important;
        .text-white {
          margin-left: 19px;
        }
      }
      .action {
        flex: 0 0 190px !important;
        max-width: 190px !important;
        height: auto !important;
        a {
          font-size: 15px !important;
          padding: 16px 45px 16px 14px;
          font-weight: 400;
          img {
            width: 49px !important;
          }
        }
      }
    }
  }
  .forSmaller {
    display: block;
  }
  .forDesktop {
    display: none;
  }
  .flight-page footer {
    padding-bottom: 160px !important;
  }
  .flipUnitContainer {
    width: 22px;
    height: 36px;
    margin-right: 5px;
    box-shadow: 0px 3px 6px 0px #00000099;
    span {
      font-size: 32px !important;
      margin-left: 0 !important;
    }
  }
}
